import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import 'uikit/dist/css/uikit.css'
import "./single-image.scss"


const SingleImage = ({ image, altText, topPadding, bottomPadding, imgWidth }) => {

    return (
        <>
            {
                image !== null ?
                    imgWidth === "Full" ?
                        <div className={`full-width-image ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''}`}>
                            <GatsbyImage image={image} loading="lazy" className="image-display" alt={altText}></GatsbyImage>
                        </div>
                        :

                        imgWidth === "Half" ?
                            <div className={`half-width-image ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''}`}>
                                <GatsbyImage image={image} loading="lazy" alt={altText}></GatsbyImage>
                            </div>
                            :

                            <div className={`single-image-container ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''}`}>
                                <GatsbyImage image={image} loading="lazy" alt={altText}></GatsbyImage>
                            </div>
                    : ""
            }
        </>
    )
}

export default SingleImage