import React from "react"
import "./cta-banner.scss"
import CustomMarkdown from "../../utils/customMarkdown"
import { FaPhone } from "react-icons/fa"


const CTABanner = ({ text, ctaUrl, ctaLabel, secondaryCtaUrl, secondaryCtaLabel, backgroundColorHex, dark = true, bgColorHex, topPadding, bottomPadding }) => {


    var CtaBannerStyle = { backgroundColor: '#' + bgColorHex }

    let newTextH2 = "";
    let newTextCustom = "";
    let newTextCustomNormal = "";

    if (text.split('#').length > 1 && text.indexOf('#') > 1) { // so it ignores in case the first entries are h2/h3 custom tags
        newTextH2 = text.split('#')[0]
        newTextCustom = text.split('#')[1]
    } else {
        newTextCustomNormal = text
    }

    return (
        <>
            <div className={`cta-banner-wrapper ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''}`} style={CtaBannerStyle}>
                <div className="cta-banner-container">
                    {newTextH2 != "" ? <h2 className="text-title-split">{newTextH2}</h2> : ""}
                    {newTextCustom != "" ? <CustomMarkdown children={newTextCustom} skipHtml={false} className="custom-markdown-btn" id="content-text"></CustomMarkdown> : ""}
                    {newTextCustomNormal != "" ? <CustomMarkdown children={text}></CustomMarkdown> : ""}

                    <div className="cta-banner-container-ctas">
                        {ctaUrl ?
                            <a className="cta-banner-container-primary-cta" href={ctaUrl}>
                                {ctaLabel}
                            </a>
                            : ""
                        }
                        {secondaryCtaUrl ?
                            <a className="cta-banner-container-secondary-cta" href={'tel:' + secondaryCtaUrl.replace(/[\s+.]/g, "")}>
                                <FaPhone size={"2rem"} />
                                <div>
                                    <div className="cta-banner-secondary-cta-label">{secondaryCtaLabel}</div>
                                    <div className="cta-banner-secondary-cta-url">{secondaryCtaUrl}</div>
                                </div>
                            </a>
                            : ""
                        }
                    </div>
                </div>
            </div>
        </>
    )

}

export default CTABanner