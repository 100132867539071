import { graphql, } from 'gatsby';
import React, { } from 'react'
import Layout from '../layouts';
import loadable from '@loadable/component'
import CTABanner from "../components/cta-banner/cta-banner"
import SingleImage from "../components/single-image/single-image"
import GetImgUrl from "../utils/customFunctions"
import checkPromoValue from "../utils/checkPromoValue"
import '../styles/broker-page.scss'
import useSiteMetadata from "../utils/useSiteMetadata"
import { GatsbyImage } from 'gatsby-plugin-image';
import CustomMarkdown from '../utils/customMarkdown';
import LinkCards from '../components/link-cards/link-cards';
import ParagraphColumn from '../components/paragraph-column/paragraph-column';
import RelatedArticles from '../components/related-articles/related-articles';
import ProviderCarousel from '../components/provider-carousel/provider-carousel';

const Header = loadable(() => import("../components/header/header"))
const Banner = loadable(() => import("../components/banner/banner"))
const Reviews = loadable(() => import("../components/reviews/reviews"))
const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
const Accordion = loadable(() => import("../components/accordion/accordion"))
const CTAList = loadable(() => import("../components/cta-list/ctaList"))
const Providers = loadable(() => import("../components/provider-list/providers"))
const Comparison = loadable(() => import("../components/comparison/comparison"))
const ImageCarousel = loadable(() => import("../components/image-carousel/image-carousel"))
const HtmlEditor = loadable(() => import("../components/html-editor/html-editor"))


export const query = graphql`
  query Brokers($id: Int) {
     strapiBrokers(strapiId: {eq: $id}) {
      Logo {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            Excerpt
            Title
            slug
            HeaderParagraph
            PageContent
            SEODescription
            SidelineParagraph
            SEOTitle
            Canonical
            Subtitle
            strapiId
  }
  strapiBrokerIndex {
    Slug
  }
  }
`;

const BrokerPage = ({ pageContext, data }) => {


    const { siteUrl: url } = useSiteMetadata()


    if (data.strapiBrokers.PageContent) {

        return (

            <Layout
                SEOTitle={data.strapiBrokers.SEOTitle || data.strapiBrokers.Title}
                SEODescription={data.strapiBrokers.SEODescription || ''}
                footerDisclaimer={''}
                canonical={data.strapiBrokers.Canonical ? data.strapiBrokers.Canonical : `${url}/${data.strapiBrokerIndex.Slug}/${data.strapiBrokers.slug}`}>

                <div className='uk-width-1-1 broker-header-container'>
                    <div className='broker-title-container '>
                        <GatsbyImage image={data.strapiBrokers?.Logo?.localFile.childImageSharp.gatsbyImageData} className='broker-image' alt="" />
                        <div className='broker-title-section'>
                            <h1>{data.strapiBrokers.Title}</h1>
                            <p>{data.strapiBrokers.Subtitle}</p>
                        </div>
                        {data.strapiBrokers.HeaderParagraph && <CustomMarkdown className="broker-secondary-subtitle" children={data.strapiBrokers.HeaderParagraph}></CustomMarkdown>}
                    </div>


                </div>



                <div className={data.strapiBrokers.SidelineParagraph ? 'page-content' : ''}>


                    <div className='page-components'>


                        {data.strapiBrokers.PageContent.map((item, index) => {


                            switch (item.strapi_component) {

                                case "page-components.header":
                                    return (

                                        <Header key={index}
                                            title={item.Title}
                                            subtitle={item.Subtitle}
                                            secondarySubtitle={item.SecondSubtitle}
                                            CTAText={item.CTALabel}
                                            CTAUrl={item.CTAUrl}
                                            CTA_nofollow={item.CTA_nofollow}
                                            Disclaimer={item.Disclaimer}
                                            Background={GetImgUrl(item.Background?.localFile___NODE, pageContext.images)}
                                            HeaderHeight={item.HeaderHeight}
                                            TextAlign={item.TextAlign}
                                        ></Header>

                                    )

                                case "page-components.cta-banner":
                                    return (

                                        <CTABanner
                                            key={index}
                                            text={checkPromoValue(item.Text, pageContext.promoInfo)}
                                            ctaUrl={item.CTAUrl}
                                            ctaLabel={item.CTALabel}
                                            secondaryCtaUrl={item.SecondaryCTAUrl}
                                            secondaryCtaLabel={item.SecondaryCTALabel}
                                            bgColorHex={item.BackgroundColorHEX}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                        ></CTABanner>

                                    )

                                case "page-components.ratings":
                                    return (

                                        <Reviews key={index}
                                            text={item.Text}
                                            ratingValue={item.RatingValue}
                                            ratingCount={item.RatingCount}
                                            reviewCount={item.ReviewCount}
                                            ratingDisclaimer={item.RatingDisclaimer}
                                            enableRichSnippet={item.EnableRichSnippet}
                                            starImage={GetImgUrl(item.StarImage?.localFile___NODE, pageContext.images).gatsbyImageData}
                                            reviewerLogo={GetImgUrl(item.ReviewerLogo?.localFile___NODE, pageContext.images).gatsbyImageData}
                                        ></Reviews>

                                    )

                                case "page-components.comparison":
                                    return (

                                        <Comparison key={index}
                                            title={item.Title}
                                            step1={item.Step1}
                                            step2={item.Step2}
                                            step3={item.Step3}
                                            image1={item.Image1 !== null ? GetImgUrl(item.Image1.localFile___NODE, pageContext.images) : null}
                                            image2={item.Image2 !== null ? GetImgUrl(item.Image2.localFile___NODE, pageContext.images) : null}
                                            image3={item.Image3 !== null ? GetImgUrl(item.Image3.localFile___NODE, pageContext.images) : null}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                            displayNumbers={(item.DisplayNumbers === null || item.DisplayNumbers === true) ? true : false}
                                        ></Comparison>


                                    )


                                case "page-components.banner":
                                    return (

                                        <Banner
                                            key={index}
                                            title={checkPromoValue(item.Title, pageContext.promoInfo)}
                                            subtitle={checkPromoValue(item.Subtitle, pageContext.promoInfo)}
                                            text={checkPromoValue(item.Text, pageContext.promoInfo)}
                                            image={item.Image != null && item.Text.includes("[*promoimage*]") ?
                                                GetImgUrl(pageContext.settingsImage, pageContext.images) :
                                                item.Image != null ?
                                                    GetImgUrl(item.Image?.localFile___NODE, pageContext.images) :
                                                    item.Text.includes("[*promoimage*]") ?
                                                        GetImgUrl(pageContext.settingsImage, pageContext.images)
                                                        : null}
                                            imagePosition={item.ImagePosition}
                                            dark={item.DarkBackground}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                            sticky={(item.Sticky === null || item.Sticky === false) ? false : true}
                                            mobileStickiness={(item.MobileStickiness === null || item.MobileStickiness === false) ? false : true}
                                        ></Banner>


                                    )

                                case "page-components.paragraph":

                                    return (

                                        <Paragraph
                                            key={index}
                                            title={item.Title}
                                            text={item.Text}
                                            image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images) : null}
                                            imagePosition={item.ImagePosition}
                                            bgColor={item.BackgroundColor || ""}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                            forceH1Title={false}
                                            alignment={item.ContentAlignment}
                                            initialPadding={item.InitialPadding}
                                        ></Paragraph>


                                    )

                                case "page-components.fa-qs":
                                    return (

                                        <Accordion
                                            key={index}
                                            title={item.Title ? item.Title : ""}
                                            contents={item.QuestionAnswer}
                                            enableSnippet={(item.EnableSnippet === null || item.EnableSnippet === false) ? false : true}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                        ></Accordion>


                                    )

                                case "page-components.providers-carousel":
                                    var Images = []
                                    item.ImageList.map((imgItem, index) => {
                                        Images.push(GetImgUrl(imgItem.Image.localFile___NODE, pageContext.images))
                                    })
                                    return (

                                        <ProviderCarousel key={index}
                                            images={Images}
                                            title={item.Title}
                                            subtitle={item.Subtitle}
                                            ctaUrl={item.CTAUrl}
                                            ctaLabel={item.CTALabel}
                                            backgroundColor={item.BackgroundColor}
                                            enableMask={item.EnableMask}
                                            imagesWhiteBg={item.ImagesWhiteBg}
                                            imageType={item.ImageType}
                                        ></ProviderCarousel>


                                    )

                                case "page-components.providers":
                                    var ImageList = []
                                    item.Images.map((imgItem, index) => {
                                        ImageList.push(GetImgUrl(imgItem.localFile___NODE, pageContext.images))
                                    })
                                    return (

                                        <Providers
                                            key={index}
                                            text={item.Title}
                                            image={ImageList}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false} />


                                    )

                                case "page-components.cta-list":
                                    var contentsList = []
                                    item.CTAList.map((CTAitem, index) => {
                                        var imageUrl = CTAitem.Image !== null ? GetImgUrl(CTAitem.Image.localFile___NODE, pageContext.images) : ""
                                        contentsList.push({
                                            Title: CTAitem.Title,
                                            URL: CTAitem.URL,
                                            URLLabel: CTAitem.URLLabel,
                                            Image: imageUrl,
                                            Alt: CTAitem.Image.alternativeText,
                                            Description: CTAitem.Description,
                                            DescriptionCharLimit: CTAitem.DescriptionCharLimit
                                        })
                                        return ("")
                                    })
                                    return (

                                        <CTAList
                                            key={index}
                                            title={item.Title ? item.Title : ""}
                                            subtitle={item.Subtitle}
                                            contents={contentsList}
                                            cardStyle={item.CardStyle}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                        ></CTAList>


                                    )

                                case "page-components.html-editor":
                                    return (

                                        <HtmlEditor
                                            key={index}
                                            htmlContent={item.HTML}
                                            fullWidth={item.FullWidth}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                        ></HtmlEditor>


                                    )

                                case "page-components.image":

                                    return (
                                        <SingleImage
                                            image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images).gatsbyImageData : null}
                                            altText={item.Image !== null ? item.Image.alternativeText : null}
                                            topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                            bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                            imgWidth={item.ImgWidth}
                                        ></SingleImage>
                                    )

                                case "page-components.link-cards":
                                    return (
                                        <LinkCards
                                            key={index}
                                            title={item.Title}
                                            bgColor={item.BackgroundColor}
                                            cards={item.Cards}
                                        />
                                    )

                                case "page-components.paragraph-column":
                                    return (
                                        <ParagraphColumn
                                            title={item.Title}
                                            bgColor={item.BackgroundColor}
                                            paragraphLeft={item.ParagraphLeft}
                                            imageLeft={item.ParagraphLeft && item.ParagraphLeft.Image !== null ? GetImgUrl(item.ParagraphLeft.Image.localFile___NODE, pageContext.images) : ""}
                                            paragraphRight={item.ParagraphRight}
                                            imageRight={item.ParagraphRight && item.ParagraphRight.Image !== null ? GetImgUrl(item.ParagraphRight.Image.localFile___NODE, pageContext.images) : ""}
                                        />
                                    )

                                case "page-components.related-articles":

                                    var articlesList = []

                                    if (item.articles.length == 0) {
                                        pageContext.articles.slice(0, 3).map((item, index) => {
                                            articlesList.push({
                                                Title: item.node.Title,
                                                slug: item.node.slug,
                                                FeaturedImage: item.node.FeaturedImage,
                                                CategorySlug: item.node.blog_category.CategorySlug,
                                                CategoryName: item.node.blog_category.CategoryName,
                                                Excerpt: item.node.Excerpt,
                                                blog_category: item.node.blog_category.CategoryName
                                            })
                                            return ("")
                                        })
                                    }

                                    return (
                                        <RelatedArticles
                                            title={item.Title}
                                            layout={item.Layout}
                                            ctaLabel={item.CTALabel ? item.CTALabel : ""}
                                            ctaUrl={item.CTAUrl ? item.CTAUrl : ""}
                                            articles={item.articles.length != 0 ? item.articles : articlesList}
                                            context={pageContext.images}

                                        />

                                    )

                                default:
                                    return ("")
                            }



                        })}
                    </div>
                    {data.strapiBrokers.SidelineParagraph ? (
                        <div className='padding-top sideline-container padding-bottom'>
                            <div className='sideline-paragraph'>
                                <CustomMarkdown children={data.strapiBrokers.SidelineParagraph} />
                            </div>
                        </div>

                    ) : ''
                    }
                </div>
            </Layout>
        )

    } else { return (<Layout>page empty. add some components.</Layout>) }
}

export default BrokerPage