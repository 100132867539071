import React, { useEffect, useRef, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "./nav.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaPhone } from "react-icons/fa"
import CustomMarkdown from "../../utils/customMarkdown"
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import ti_light from "../../assets/ti_white_logo.svg"
import ti_dark from "../../assets/ti_logo.svg";
import van_logo from "../../assets/ti_van_logo.svg";
import bike_logo from "../../assets/ti_bike_logo.svg";
import motorhome_logo from "../../assets/ti_motorhome_logo.svg";
import caravan_logo from "../../assets/ti_caravan_logo.svg";
import van from "../../assets/ti_van_white.svg";
import bike from "../../assets/ti_bike_white.svg";
import motorhome from "../../assets/ti_motorhome_white.svg";
import caravan from "../../assets/ti_caravan_white.svg";
import van_pink from "../../assets/ti_van_pink.svg";
import bike_pink from "../../assets/ti_bike_pink.svg";
import motorhome_pink from "../../assets/ti_motorhome_pink.svg";
import caravan_pink from "../../assets/ti_caravan_pink.svg";

const Nav = ({ forcedDarkTheme, hideQuoteButton, hidePhoneButton, overwriteQuoteButtonURL, overwritePhoneButtonNumber  }) => {

    const data = useStaticQuery(graphql`
        {
            strapiMenu{
                MenuList{
                    Title
                    URL
                    DisplayOnMobile
                    Link_nofollow
                }
                GetQuoteTitle
                GetQuoteURL
                QuotePhoneNumber
                QuotePhoneTitle
            }

            strapiSiteSettings{
                LogoDark{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                LogoLight{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }

                StarRatingImage{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                ReviewerImage{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                ReviewShortText
                Rating
                TotalReviews
                LayoutTheme
                SiteTitle
                
            }
        }
    
    `)


    const [ctaBarVisible, setCtaBarVisibility] = useState(false)
    const menu = useRef(null);
    const mobileMenu = useRef(null)

    const getQuoteURL = overwriteQuoteButtonURL ? overwriteQuoteButtonURL : data.strapiMenu.GetQuoteURL;
    const getPhoneNumber = overwritePhoneButtonNumber ? overwritePhoneButtonNumber : data.strapiMenu.QuotePhoneNumber;
    //console.log("[LINKS]" + getQuoteURL + "..." + getPhoneNumber)
    let dark_logo = null;
    let light_logo = null;//toDo: when we'll include the light logos as svg inside assets.
    switch(data.strapiSiteSettings.SiteTitle)
    {
        case "The Car Insurer":
        case "The Insurers":
            dark_logo = ti_dark;
            break;
        case "The Caravan Insurer":
            dark_logo =  caravan_logo;
            break;
        case "The Bike Insurer":
            dark_logo =  bike_logo;
            break;
        case "The Van Insurer":
            dark_logo =  van_logo;
            break;
        case "The Motorhome Insurer":
            dark_logo =  motorhome_logo;
            break;
    }

    function showHideCta() {
        var y = window.scrollY;
        if (y >= 400) {
            setCtaBarVisibility(true)
        } else {
            setCtaBarVisibility(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", showHideCta)
    }, [])


    const [openMenu, setOpenMenu] = useState(false)
    const [openMobileMenu, setOpenMobileMenu] = useState(false)

    const closeMenu = (e) => {
        if (menu.current && openMenu && !menu.current.contains(e.target)) {
            setTimeout(() => {
                setOpenMenu(false)
            }, 90)
        }
    }
    const closeMobileMenu = (e) => {
        if (mobileMenu.current && openMobileMenu && !mobileMenu.current.contains(e.target)) {
            setTimeout(() => {
                setOpenMobileMenu(false)
            }, 90)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', closeMenu)
        document.addEventListener('mousedown', closeMobileMenu)
        return () => {
            window.removeEventListener('mousedown', closeMenu);
            window.removeEventListener('mousedown', closeMobileMenu);
        };
    }, [openMenu, openMobileMenu])

    const [showElement, setShowElement] = useState(false)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true)
        }, 300);
    },
        [])


    // Function to update showPopup state with a check
    const shouldUpdateShowPopup = (value) => {
        if (data.strapiSiteSettings.LayoutTheme == "TI") { 
        setShowPopup(value);
        } else {
            setShowPopup('');
        //console.log('Condition not met, state not updated');
        }
    };

    // console.log(hideQuoteButton, "hide")

    const [showPopup, setShowPopup] = useState('')
    const [insurerURL, setInsurerURL] = useState('')


    const [switchImage, setSwitchImage] = useState(false)

    const onHover = () => {
        setSwitchImage(true);
    };
    const onLeave = () => {
        setSwitchImage(false);
    };

        

    return (
        <div>
            <div className={"nav-dark-theme navigationBar"}>
                <div data-uk-sticky=" sel-target: .uk-navbar-container; top:1;">
                    {/* nav bar with the logo */}
                    <nav className="nav-options uk-navbar-container  " data-uk-navbar>
                        <div className="uk-navbar-left nav-options-left" >

                            {
                                data.strapiSiteSettings.LayoutTheme != "TI" ?

                                    <div className={`menu-toggle uk-visible@m ${openMenu && 'menu-open'}`}
                                        ref={menu}
                                    >
                                        <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn " data-uk-toggle="target: #offcanvas-menu"
                                            onClick={() => setOpenMenu(!openMenu)}
                                        >
                                            <span className="ui-label">{openMenu ? <>Close <AiOutlineClose size={'1.1rem'} /></> : <>Menu <AiOutlineMenu size={'1.1rem'} /></>}</span>

                                        </a>
                                    </div>
                                    :
                                    ""
                            }



                            <ul className="uk-navbar-nav">
                                <li>
                                    <a className="logoLight" href="/" rel="nofollow" aria-label="light-logo">
                                        {data.strapiSiteSettings.LogoLight.localFile.childImageSharp ?
                                            data.strapiSiteSettings.LayoutTheme != "TI" ?
                                                light_logo ?
                                                <img src={light_logo} alt="Logo light" width="150" height="34" /> : 
                                                <GatsbyImage image={data.strapiSiteSettings.LogoLight.localFile.childImageSharp.gatsbyImageData} loading="eager" alt="The insurers logo light" width="180" height="34"></GatsbyImage>
                                                :
                                                <img src={ti_light} alt="The insurers logo light" width="150" height="34" /> : ""
                                        }

                                    </a>
                                    <a className="logoDark" href="/" rel="nofollow">
                                        {data.strapiSiteSettings.LogoDark.localFile.childImageSharp ?
                                            data.strapiSiteSettings.LayoutTheme != "TI" ?
                                                dark_logo ?
                                                <img src={dark_logo} alt="Logo dark" width="150" height="34" /> : 
                                                <GatsbyImage image={data.strapiSiteSettings.LogoDark.localFile.childImageSharp.gatsbyImageData} loading="eager" alt="The insurers logo dark" width="180" height="34"></GatsbyImage>
                                                :
                                                <img src={ti_dark} alt="The insurers logo dark" width="150" height="34" /> : ""
                                        }

                                    </a>
                                </li>
                            </ul>
                        </div>

                        {/* middle navbar */}
                        <div className={data.strapiSiteSettings.LayoutTheme == "TI" ? "desktop-links uk-visible@m" : "uk-navbar-center uk-visible@m"}>
                            <ul className="uk-navbar-nav menu-items-nav">
                                {
                                    data.strapiMenu ?
                                        data.strapiSiteSettings.LayoutTheme == "TI" ?
                                            data.strapiMenu.MenuList.slice(0, 5).map((item, index) => {
                                                return (
                                                    <li key={index} className={`menu-item-visible-index-` + index}>
                                                        {
                                                            item.Title == "Car Insurance" ?
                                                                <a href={item.URL} rel={item.Link_nofollow ? "nofollow" : ""}>{item.Title}</a>
                                                                :
                                                                <button className="menu-item-btn" onClick={() => { shouldUpdateShowPopup(item.Title); setInsurerURL(item.URL) }}>{item.Title}</button>
                                                        }
                                                    </li>
                                                )

                                            })

                                            :

                                            data.strapiMenu.MenuList.slice(0, 3).map((item, index) => {
                                                return (
                                                    <li key={index} className={`menu-item-visible-index-` + index}>
                                                        {/* <Link to={`/${item.URL.replace('/', '')}`}>{item.Title}</Link> */}
                                                        <a href={item.URL} rel={item.Link_nofollow ? "nofollow" : ""}>{item.Title}</a>
                                                    </li>
                                                )

                                            })

                                        : ""
                                }
                            </ul>
                        </div>



                        {/* right navbar menu toggle button hidden on desktop but shown on mobile - align-right*/}
                        {showElement ?
                            <div className="uk-navbar-right uk-animation-fade" ref={mobileMenu}>

                                <div className="nav-right-container">
                                    {getPhoneNumber && (hidePhoneButton == false || hidePhoneButton == undefined)  ? <a href={'tel:' + getPhoneNumber.replace(/[\s+.]/g, "")} className="nav-tel uk-visible@m">
                                        <div className="quote-phone-title">{data.strapiMenu.QuotePhoneTitle}</div>
                                        <div>{getPhoneNumber}</div>
                                    </a> : ""}

                                    {data.strapiMenu.GetQuoteURL && (hideQuoteButton == false || hideQuoteButton == undefined) ?
                                        <a className="nav-cta uk-visible@m" href={getQuoteURL}>
                                            {data.strapiMenu.GetQuoteTitle}
                                        </a> : ""}
                                </div>

                                {
                                    openMobileMenu ? <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn uk-hidden@m" data-uk-toggle="target: #offcanvas-menu" onClick={() => setOpenMobileMenu(false)}>
                                        {data.strapiSiteSettings.LayoutTheme == "TI" ?
                                            <span style={{ fontWeight: "bold" }}>Menu <AiOutlineClose size={'1rem'} style={{ paddingBottom: "1px" }} /></span> : <span className="uk-margin-small-right">Close <AiOutlineClose size={'1.1rem'} /></span>}
                                    </a>
                                        :
                                        <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn uk-hidden@m open-menu" data-uk-toggle="target: #offcanvas-menu" onClick={() => setOpenMobileMenu(true)}>
                                            {data.strapiSiteSettings.LayoutTheme == "TI" ?
                                                <span style={{ fontWeight: "bold" }}>Menu<AiOutlineMenu size={'1rem'} style={{ paddingBottom: "1px", marginLeft: "5px" }} /></span> : <span className="uk-margin-small-right" >Menu<AiOutlineMenu size={'1rem'} /></span>}
                                        </a>
                                }


                            </div>
                            : ""
                        }

                        {/* mobile side menu only visible on mobile */}
                        <div id="offcanvas-menu" className="off-canv" data-uk-offcanvas="overlay: false; flip: false; mode: none" style={openMobileMenu ? { display: 'block' } : { display: 'none' }}>
                            <div className="uk-offcanvas-bar uk-flex uk-flex-column offcanvas-container canvas-container-mobile uk-animation-slide-right-small" id={data.strapiSiteSettings.LayoutTheme == "TI" ? "menu-bg-color" : "default"}>

                                {/**
                                 * offcanvas call to action menu only visible on tablet & mobile
                                 */}

                                {
                                    data.strapiSiteSettings.LayoutTheme != "TI" ?
                                        <div className="offcanvas-menu-cta-wrapper uk-hidden@m">
                                            <div className="offcanvas-menu-cta-container ">
                                                {data.strapiMenu.QuotePhoneTitle && (hidePhoneButton == false || hidePhoneButton == undefined)? <a className="offcanvas-menu-cta-container-primary-cta" href={'tel:' + getPhoneNumber.replace(/[\s+.]/g, "")}>
                                                    <FaPhone size={"2rem"} />
                                                    <span>{data.strapiMenu.QuotePhoneTitle}</span>
                                                </a> : ""}
                                                {data.strapiMenu.GetQuoteTitle && (hideQuoteButton == false || hideQuoteButton == undefined) ? <a className="offcanvas-menu-cta-container-secondary-cta" href={getQuoteURL}>
                                                    {data.strapiMenu.GetQuoteTitle}
                                                </a> : ""}
                                            </div>
                                        </div>
                                        : ""
                                }


                                {/**
                                  * List of menu links
                                  * The first 3 links will be hidden on desktop because they are
                                  * visible in the main top menu but when resizing for mobile they
                                  * will become visible again in the offcanvas side menu.
                                  */}
                                <ul className="uk-nav uk-nav-primary uk-nav-left offcanvas-menu-list reposition">

                                    {data.strapiMenu ? data.strapiMenu.MenuList.map((item, index) => {
                                        return (
                                            item.DisplayOnMobile ?
                                                <li key={index} className={`offcanvas-menu-item-visible-index-` + index}>
                                                    {
                                                        data.strapiSiteSettings.LayoutTheme != "TI"                                                    
                                                        ?<a href={item.URL} rel={item.Link_nofollow ? "nofollow" : ""}>{item.Title}</a>:
                                                        item.Title == "Car Insurance" ?
                                                            <a href={item.URL} rel={item.Link_nofollow ? "nofollow" : ""}>{item.Title}</a>
                                                            :
                                                            <button className="mobile-item-btn" onClick={() => { shouldUpdateShowPopup(item.Title); setInsurerURL(item.URL) }}>{item.Title}</button>
                                                    }
                                                </li>
                                                : ""
                                        )
                                    }) : ""
                                    }

                                </ul>

                                {/**
                                 * REVIEWS.IO section
                                 */}
                                {(data.strapiSiteSettings.TotalReviews > 0) ? <div className="offcanvas-reviews-wrapper uk-hidden@m">
                                    <div className="offcanvas-reviews-images-container">
                                        <GatsbyImage image={data.strapiSiteSettings.StarRatingImage.localFile.childImageSharp.gatsbyImageData} alt="Rating stars"></GatsbyImage>
                                        <GatsbyImage image={data.strapiSiteSettings.ReviewerImage.localFile.childImageSharp.gatsbyImageData} alt="Rating stars"></GatsbyImage>
                                    </div>
                                    <div className="offcanvas-reviews-text-container">
                                        {<CustomMarkdown children={data.strapiSiteSettings.ReviewShortText} />}
                                    </div>
                                </div> : ""}





                            </div>

                        </div>

                    </nav>

                    {/**
                     * CTA bar that's visible only on mobile when user scrolls a bit down, only if it has any buttons visible
                     */
                        ctaBarVisible && (hidePhoneButton == false || hidePhoneButton == undefined || hideQuoteButton == false || hideQuoteButton == undefined) ?
                            data.strapiSiteSettings.LayoutTheme == "TI" ?
                                //     <div className="ti-nav-cta-wrapper uk-hidden@m" data-uk-scrollspy="cls:uk-animation-fade">
                                //         <div className="ti-nav-cta-container ">
                                //             <a className="ti-nav-cta-container-primary-cta" href={''}>
                                //                 {/* <FaPhone size={"2rem"} /> */}
                                //                 <span>Retrieve a quote</span>
                                //             </a>
                                //             <a className="ti-nav-cta-container-secondary-cta" href={''}>
                                //                 Compare insurance
                                //             </a>
                                //         </div>
                                //     </div>
                                //     :
                                ""
                                :
                                <div className="nav-cta-wrapper uk-hidden@m" data-uk-scrollspy="cls:uk-animation-fade">
                                    <div className="nav-cta-container ">
                                        {
                                            (hidePhoneButton == false || hidePhoneButton == undefined) ?
                                            <a className="nav-cta-container-primary-cta" href={'tel:' + getPhoneNumber.replace(/[\s+.]/g, "")}>
                                                <FaPhone size={"2rem"} />
                                                <span>{data.strapiMenu.QuotePhoneTitle}</span>
                                            </a>
                                            : ""
                                        }
                                        {
                                            (hideQuoteButton == false || hideQuoteButton == undefined) ?
                                                <a className="nav-cta-container-secondary-cta" href={getQuoteURL}>
                                                    {data.strapiMenu.GetQuoteTitle}
                                                </a>
                                                : ""
                                        }

                                    </div>
                                </div>
                            : ""
                    }



                </div>
            </div>

            {showPopup != '' ?
                <div className="popup-overlay-for-ti" uk-scrollspy="cls: uk-animation-fade; target: .popup; delay: 10; repeat: true">
                    <div className="popup">
                        <span className="close" onClick={() => shouldUpdateShowPopup('')}>&times;</span>
                        <div className="popup-content">
                            <img className="insurer-logo"
                                src={showPopup == "Van Insurance" ? van_logo :
                                    showPopup == "Motorbike Insurance" ? bike_logo :
                                        showPopup == "Motorhome Insurance" ? motorhome_logo :
                                            showPopup == "Caravan Insurance" ? caravan_logo : ""} />

                            <p>You are about to go to The
                                {showPopup == "Van Insurance" ? " Van " :
                                    showPopup == "Motorbike Insurance" ? " Bike " :
                                        showPopup == "Motorhome Insurance" ? " Motorhome " :
                                            showPopup == "Caravan Insurance" ? " Caravan " : ""}
                                Insurer</p>

                            <a className="cta" href={insurerURL != '' ? insurerURL : ""} onMouseEnter={onHover} onMouseLeave={onLeave}>
                                <img
                                    className="mini-logo"
                                    src={(showPopup == "Van Insurance" && switchImage) ? van_pink :
                                        showPopup == "Van Insurance" ? van :
                                            (showPopup == "Motorbike Insurance" && switchImage) ? bike_pink :
                                                showPopup == "Motorbike Insurance" ? bike :
                                                    (showPopup == "Motorhome Insurance" && switchImage) ? motorhome_pink :
                                                        showPopup == "Motorhome Insurance" ? motorhome :
                                                            (showPopup == "Caravan Insurance" && switchImage) ? caravan_pink :
                                                                showPopup == "Caravan Insurance" ? caravan : ""} />
                                <span>Let's go!</span>
                            </a>
                            <p className="span-text" onClick={() => setShowPopup('')}>Stay at The Insurers</p>
                        </div>
                    </div>
                </div>
                : ""
            }
        </div>
    )
}

export default Nav